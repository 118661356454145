<template>
  <div>
    <loading-overlay :active="isLoading" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="getModalTitle"
      color="dark"
      size="xl"
      :class="FgGeneralCargo ? '' : 'modal-extended'"
      :show.sync="modalActive"
      @update:show="toggle(false)"
    >
      <CRow>
        <CCol sm="12">
          <CCard class="card-simple">
            <CCardBody>
              <CRow>
                <CCol sm="12">
                  <CRow>
                    <CCol sm="12" lg="5">
                      <div class="form-group form-row">
                        <label class="text-right col-sm-12 col-lg-6 col-form-label-sm">{{
                          $t('label.available') + " (TON)"
                        }}</label>
                        <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                          <money
                            v-model="generalAvailable"
                            v-bind="measure"
                            class="form-control"
                            value="0"
                            disabled
                          >
                          </money>
                        </div>
                      </div>
                    </CCol>
                    <CCol sm="12" lg="5">
                      <div class="form-group form-row">
                        <label class="text-right col-sm-12 col-lg-6 col-form-label-sm">{{
                          $t('label.occupied') + " (TON)"
                        }}</label>
                        <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                          <money
                            v-model="generalOccupied"
                            v-bind="measure"
                            class="form-control"
                            value="0"
                            disabled
                          >
                          </money>
                        </div>
                      </div>
                    </CCol>
                    <CCol sm="12" lg="auto" class="d-flex justify-content-end align-items-start mb-2">
                        <CButton 
                          :color="`${collapse ? 'wipe':'add'}`"
                          size="sm"
                          shape="square"
                          @click="toggleCollapse"
                          v-c-tooltip="{
                            content: $t('label.nueva') + ' ' + $t('label.assignation'),
                            placement: 'top-center',
                          }"
                        >
                          <CIcon :name="`${collapse ? 'cil-minus':'cil-plus'}`" />
                        </CButton>
                    </CCol>
                  </CRow>
                </CCol>

                <CCol sm="12">
                  <CCollapse :show="collapse">
                    <CRow>
                      <CCol sm="12">
                        <CCard>
                          <CCardHeader color="dark" class="text-center">
                            {{ $t("label.plannedAssignment") }}
                          </CCardHeader>
                          <CCardBody>
                            <CRow>
                              <CCol sm="12" lg="10">
                                <CRow>
                                  <CCol sm="12" lg="6">
                                    <CSelect
                                      v-if="!edit"
                                      size="sm"
                                      :placeholder="$t('label.select')"
                                      addLabelClasses="required text-right"
                                      :label="$t('label.assignBl')"
                                      :horizontal="{
                                        label: 'col-sm-12 col-lg-6',
                                        input: 'col-sm-12 col-lg-6',
                                      }"
                                      :options="blOptions"
                                      :disabled="isEmpty(bls)"
                                      :value.sync="
                                        $v.planned.StowagePlanningBillOfLadingId.$model
                                      "
                                      :invalid-feedback="
                                        errorMessage(
                                          $v.planned.StowagePlanningBillOfLadingId
                                        )
                                      "
                                      :is-valid="
                                        hasError($v.planned.StowagePlanningBillOfLadingId)
                                      "
                                      @change="currentBl($event.target.value)"
                                    >
                                    </CSelect>
                                    <CInput
                                      v-if="edit"
                                      addLabelClasses="required text-right"
                                      :horizontal="{
                                        label: 'col-sm-12 col-lg-6',
                                        input: 'col-sm-12 col-lg-6',
                                      }"
                                      :label="$t('label.assignBl')"
                                      size="sm"
                                      :value="getHoldBl"
                                      disabled
                                    />
                                  </CCol>
                                  <CCol sm="12" lg="6" v-if="!FgGeneralCargo">
                                    <div class="form-group form-row">
                                      <label class="text-right col-sm-12 col-lg-6 col-form-label-sm">{{
                                        $t('label.declared') + " (TON)"
                                      }}</label>
                                      <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                                        <money
                                          v-model.trim="blDeclared"
                                          v-bind="measure"
                                          class="form-control"
                                          value="0"
                                          disabled
                                        >
                                        </money>
                                      </div>
                                    </div>
                                  </CCol>
                                  <CCol sm="12" lg="6" v-if="FgGeneralCargo">
                                    <CSelect
                                      v-if="!edit"
                                      size="sm"
                                      :placeholder="$t('label.select')"
                                      addLabelClasses="required text-right"
                                      :label="$t('label.detail')"
                                      :horizontal="{label: 'col-sm-12 col-lg-6', input: 'col-sm-12 col-lg-6'}"
                                      :options="PackagingOptions"
                                      v-model="$v.planned.StowagePlanningBillOfLadingPackagingId.$model"
                                      :invalid-feedback="errorMessage($v.planned.StowagePlanningBillOfLadingPackagingId)"
                                      :is-valid="hasError($v.planned.StowagePlanningBillOfLadingPackagingId)"
                                    >
                                    </CSelect>
                                    <CInput
                                      v-if="edit"
                                      addLabelClasses="required text-right"
                                      :horizontal="{
                                        label: 'col-sm-12 col-lg-6',
                                        input: 'col-sm-12 col-lg-6',
                                      }"
                                      :label="$t('label.detail')"
                                      size="sm"
                                      :value.async="DataPackaging?.PackagingName"
                                      disabled
                                    />
                                  </CCol>
                                  <CCol sm="12" lg="6">
                                    <div class="form-group form-row">
                                      <label class="text-right col-sm-12 col-lg-6 col-form-label-sm m-0">{{`${$t('label.availabilityByBl')} (TON)`}}</label>
                                      <div class="input-group col-sm-12 col-lg-6 input-group-sm align-items-center">
                                        <money
                                          v-model.trim="blAvailable"
                                          v-bind="measure"
                                          class="form-control"
                                          value="0"
                                          disabled
                                        >
                                        </money>
                                      </div>
                                    </div>
                                  </CCol>
                                  <CCol sm="12" lg="6" v-if="FgGeneralCargo">
                                    <div class="form-group form-row">
                                      <label class="text-right col-sm-12 col-lg-6 col-form-label-sm m-0">{{`${ValidatePackaging ? $t('label.AvailabilityByPackaging') : $t('label.AvailabilityByDetail')} (TON)`}}</label>
                                      <div class="input-group col-sm-12 col-lg-6 input-group-sm align-items-center">
                                        <money
                                          :value.async="DataPackaging?.Weight"
                                          v-bind="measure"
                                          class="form-control"
                                          disabled
                                        >
                                        </money>
                                      </div>
                                    </div>
                                  </CCol>
                                  <CCol sm="12" lg="6" v-if="FgGeneralCargo">
                                    <div class="form-group form-row">
                                      <label class="text-right col-sm-12 col-lg-6 col-form-label-sm">{{
                                        $t('label.declared') + " (TON)"
                                      }}</label>
                                      <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                                        <money
                                          v-model.trim="blDeclared"
                                          v-bind="measure"
                                          class="form-control"
                                          value="0"
                                          disabled
                                        >
                                        </money>
                                      </div>
                                    </div>
                                  </CCol>
                                  <CCol sm="12" lg="6" v-if="FgGeneralCargo">
                                    <div class="form-group form-row">
                                      <label class="text-right col-sm-12 col-lg-6 col-form-label-sm m-0">{{`${ValidatePackaging ? $t('label.QuantityAvailabilityPerPackaging') : $t('label.QuantityAvailabilityPerDetail')}`}}</label>
                                      <div class="input-group col-sm-12 col-lg-6 input-group-sm align-items-center">
                                        <money
                                          :value.async="DataPackaging?.Quantity"
                                          v-bind="measure"
                                          class="form-control"
                                          disabled
                                        >
                                        </money>
                                      </div>
                                    </div>
                                  </CCol>
                                  <CCol sm="12" lg="6">
                                    <div class="form-group form-row">
                                      <label class="text-right col-sm-12 col-lg-6 col-form-label-sm">{{
                                        $t('label.occupied') + " (TON)"
                                      }}</label>
                                      <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                                        <money
                                          v-model.trim="blOccupied"
                                          v-bind="measure"
                                          class="form-control"
                                          value="0"
                                          disabled
                                        >
                                        </money>
                                      </div>
                                    </div>
                                  </CCol>
                                  <CCol sm="12" lg="6">
                                    <CInput
                                      size="sm"
                                      ref="weightInput"
                                      :label="$t('label.assignTon')"
                                      :horizontal="{
                                        label: 'col-sm-12 col-lg-6',
                                        input: 'col-sm-12 col-lg-6',
                                      }"
                                      placeholder="0"
                                      addLabelClasses="required text-right"
                                      v-model.trim="planned.WeightAsigned"
                                      v-money="measure"
                                      @focus="$v.planned.WeightAsigned.$touch()"
                                      maxlength="9"
                                      :is-valid="
                                        hasError($v.planned.WeightAsigned)
                                      "
                                      required
                                    >
                                      <template #append>
                                        <CButton
                                          color="watch"
                                          size="sm"
                                          :disabled="ValidateAssignmentButton"
                                          v-c-tooltip="{
                                            content: $t('label.AssignAll'),
                                            placement: 'top-end',
                                          }"
                                          style="padding: 0.15rem 0.4rem; border-radius: 0.2rem;"
                                          @click="AssignAll"
                                        >
                                          <CIcon name="SelectAll" />
                                        </CButton>
                                      </template>
                                      <template #invalid-feedback>
                                        <div v-if="$v.planned.WeightAsigned.$error" class="invalid-feedback">
                                          <div v-if="FgGeneralCargo">
                                            <p v-if="!$v.planned.WeightAsigned.notAvailableBl" class="mb-0">{{ $t('validation.notAvailableBl') }}</p>
                                            <p v-else-if="!$v.planned.WeightAsigned.notAvailableDetail" class="mb-0">
                                              {{ `${ValidatePackaging ? $t('validation.notAvailablePackaging') : $t('validation.notAvailableDetail')}` }}
                                            </p>
                                            <p v-else-if="!$v.planned.WeightAsigned.ValidateTotalPackaging" class="mb-0">
                                              {{ `${ValidatePackaging ? $t('validation.WeightAvailabilityPerPackaging') : $t('validation.WeightAvailabilityPerDetail')}` }}
                                            </p>
                                            <p v-else class="mb-0"> 
                                              {{ errorMessage($v.planned.WeightAsigned) }}
                                            </p>
                                          </div>
                                          <div v-else>
                                            {{ errorMessage($v.planned.WeightAsigned) }}
                                          </div>
                                        </div>
                                      </template>
                                    </CInput>
                                  </CCol>
                                  <CCol sm="12" lg="6" v-show="FgGeneralCargo">
                                    <CInput
                                      size="sm"
                                      ref="QuantityInput"
                                      :label="$t('label.quantity')"
                                      :horizontal="{
                                        label: 'col-sm-12 col-lg-6',
                                        input: 'col-sm-12 col-lg-6',
                                      }"
                                      placeholder="0"
                                      addLabelClasses="required text-right"
                                      v-model.trim="planned.QuantityAsigned"
                                      v-money="measure"
                                      @focus="$v.planned.QuantityAsigned.$touch()"
                                      maxlength="9"
                                      :is-valid="
                                        hasError($v.planned.QuantityAsigned)
                                      "
                                    >
                                      <template #invalid-feedback>
                                        <div v-if="$v.planned.QuantityAsigned.$error" class="invalid-feedback">
                                          <p v-if="!$v.planned.QuantityAsigned.maxValue" class="mb-0">
                                            {{ `${$t('validation.maxValueIs')}: ${DataPackaging?.Quantity ?? 0}` }}
                                          </p>
                                          <p v-else-if="!$v.planned.QuantityAsigned.ValidateTotalPackaging" class="mb-0">
                                            {{ `${ValidatePackaging ? $t('validation.QuantityAvailabilityPerPackaging') : $t('validation.QuantityAvailabilityPerDetail')}` }}
                                          </p>
                                          <p v-else class="mb-0">
                                            {{ errorMessage($v.planned.QuantityAsigned)  }}
                                          </p>
                                        </div>
                                      </template>
                                    </CInput>
                                  </CCol>
                                  <CCol sm="12" lg="6">
                                    <CInput
                                      v-model="$v.planned.Color.$model"
                                      :invalid-feedback="
                                        errorMessage($v.planned.Color)
                                      "
                                      :is-valid="hasError($v.planned.Color)"
                                      size="sm"
                                      type="color"
                                      :horizontal="{
                                        label: 'col-sm-12 col-lg-6',
                                        input: 'col-sm-12 col-lg-6',
                                      }"
                                      :label="$t('label.color')"
                                      addLabelClasses="required text-right"
                                      required
                                    />
                                  </CCol>
                                  <CCol sm="12" lg="6" v-if="edit">
                                    <div>
                                      <CSelect
                                        :value.sync="planned.Status"
                                        :is-valid="statusSelectColor()"
                                        :horizontal="{
                                          label: 'col-sm-12 col-lg-6',
                                          input: 'col-sm-12 col-lg-6',
                                        }"
                                        size="sm"
                                        :label="$t('label.status')"
                                        :options="statusOptions"
                                        addLabelClasses="text-right"
                                      />
                                    </div>
                                  </CCol>
                                </CRow>
                              </CCol>
                              <CCol
                                sm="12" lg="auto"
                                class="d-flex justify-content-end align-items-start mb-3"
                              >
                                <CButton
                                  v-if="!edit"
                                  color="add"
                                  shape="square"
                                  size="sm"
                                  :disabled="isSubmit"
                                  @click="submit"
                                >
                                  <div v-if="!isSubmit">
                                    <CIcon name="checkAlt" />
                                  </div>
                                  <div v-if="isSubmit">
                                    <span
                                      class="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  </div>
                                </CButton>
                                <CButton
                                  v-if="edit"
                                  color="add"
                                  shape="square"
                                  size="sm"
                                  :disabled="isSubmit"
                                  @click="confirmation"
                                >
                                  <div v-if="!isSubmit">
                                    <CIcon name="checkAlt" />
                                  </div>
                                  <div v-if="isSubmit">
                                    <span
                                      class="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  </div>
                                </CButton>
                                <CButton
                                  class="ml-1"
                                  color="wipe"
                                  size="sm"
                                  shape="square"
                                  v-c-tooltip="{
                                    content: $t('label.clearFields'),
                                    placement: 'top-end',
                                  }"
                                  @click="resetForm"
                                >
                                  <CIcon name="cil-brush-alt" />
                                </CButton>
                              </CCol>
                            </CRow>
                          </CCardBody>
                        </CCard>
                      </CCol>
                    </CRow>
                  </CCollapse>                
                </CCol>

                <CCol sm="12">
                  <dataTableExtended
                    class="align-center-row-datatable"
                    hover
                    small
                    sorter
                    column-filter
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :noItemsView="tableText.noItemsViewText"
                    :items="formatedItems"
                    :fields="fields"
                    :items-per-page="5"
                    :active-page="activePage"
                    pagination
                  >
                    <template #Status="{ item }">
                      <td class="text-center align-middle">
                        <div>
                          <CBadge :color="getBadge(item.Status)">
                            {{ item.Status ? $t("label." + item.Status) : 'N/A'}}
                          </CBadge>
                        </div>
                      </td>
                    </template>

                    <template #Details="{ item }">
                      <td class="center-cell">
                        <CButton
                          shape="square"
                          color="edit"
                          size="sm"
                          class="mr-1"
                          v-c-tooltip="{
                            content: $t('label.edit') + ' ' + 'BL',
                            placement: 'top-start',
                          }"
                          @click="editBl(item)"
                        >
                          <CIcon name="pencil" />
                        </CButton>
                      </td>
                    </template>
                  </dataTableExtended>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <template #footer>
        <CButton shape="square" color="wipe" :disabled="isSubmit" @click="toggle(false)">
          <CIcon name="x" />&nbsp; {{ $t("button.cancel") }}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>

<script>
import ModalMixin from "@/_mixins/modal";
import General from "@/_mixins/general";
import { tableText } from "@/_helpers/funciones";
import { formatMilDecimal } from "@/_validations/validacionEspeciales";
import ManifestStowagePlanValidation from "@/_validations/planificacion-estiba/ManifestStowagePlanValidation";
import { VMoney, Money } from "v-money";
import { mapState } from "vuex";
import { DateFormater, NumberFormater } from "@/_helpers/funciones";

function fields() {
  if (this.FgGeneralCargo) {
    return [
      {
        key: "Details",
        label: "",
        sorter: false,
        filter: false,
        _styles: "width: 1%; min-width: 45px",
      },
      {
        key: "Nro",
        label: "#",
        _style: "min-width: 45px",
        filter: false,
      },
      { key: "ClientName", label: this.$t("label.client"), _classes: "text-center", _style: "min-width: 130px" },
      { key: "NroBl", label: "BL", _classes: "text-center", _style: "min-width: 130px" },
      { key: "CommodityName", label: this.$t("label.commodity"), _classes: "text-center", _style: "min-width: 130px" },
      { key: "Density", label: this.$t("label.density"), _classes: "text-center", _style: "min-width: 130px" },
      { key: "PackagingName", label: this.$t("label.packaging"), _classes: "text-center", _style: "min-width: 130px" },
      { key: "FormatWeight", label: this.$t("label.tonAssigned")+' (TON)', _classes: "text-center", _style: "min-width: 130px" },
      { key: "FormatQuantity", label: this.$t("label.quantity"), _classes: "text-center", _style: "min-width: 130px" },
      { key: "Usuario", label: this.$t("label.user"), _classes: "text-center", _style: "min-width: 130px" },
      { key: "Fecha", label: this.$t("label.date"), _classes: "text-center", _style: "min-width: 130px" },
      { key: "Status", label: this.$t("label.status"), _classes: "text-center", _style: "min-width: 130px" },
    ];
  } else {
    return [
      {
        key: "Nro",
        label: "#",
        _style: "width:1%;",
        filter: false,
      },
      { key: "ClientName", label: this.$t("label.client"), _classes: "text-center" },
      { key: "NroBl", label: "BL" },
      { key: "FormatWeight", label: this.$t("label.tonAssigned")+' (TON)', _classes: "text-center" },
      { key: "Usuario", label: this.$t("label.user") },
      { key: "Fecha", label: this.$t("label.date"), _classes: "text-center", _classes: "text-center" },
      { key: "Status", label: this.$t("label.status"), _classes: "text-center", _classes: "text-center" },
      {
        key: "Details",
        label: "",
        sorter: false,
        filter: false,
        _styles: "width: 1%; min-width: 45px",
      },
    ];
  }
}
function cellTableClasses() {
  return {
    Nro: "align-middle",
    NroBl: "align-middle",
    ClientName: "align-middle",
    FormatWeight: "align-middle",
    Usuario: "align-middle",
    Fecha: "align-middle",
    Status: "align-middle",
  };
}

//Data
function data() {
  return {
    modalActive: this.modal,
    isLoading: false,
    activePage: 1,
    items: [],
    EditWeightAsigned: 0,
    available: "0",
    occupied: "0",
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
    },
    planned: {
      StowagePlanningBlHoldId: '',
      StowagePlanningBillOfLadingId: "", 
      StowagePlanningBillOfLadingPackagingId: '',
      VesselHoldId: "",
      UnitMeasureWeightBlHoldId: process.env.VUE_APP_UNIT_MEASURE_WEIGHT_ID_TON,
      Color: "",
      WeightAsigned: "",
      QuantityAsigned: "",
      Status: 1,
    },
    edit: false,
    oldStatus: 1,
    collapse: false,
    bls: [],
    blOccupied: 0,
    blAvailable: 0,
    blDeclared: 0,
    generalAvailable: 0,
    generalOccupied: 0,
    PackingTonAvailable: 0,
    PackingQuantityAvailable: 0,
    PackagingList: [],
  };
}

//Methods
function toggle(newVal) {
  if(!newVal) {
    this.resetForm();
    this.$emit("closed");
    this.collapse = false;
    this.bls = [];
    this.items = [];
    this.generalAvailable = 0;
    this.generalOccupied = 0;
  } else {
    this.loadData();
  }
  this.modalActive = newVal;
}
function loadData() {
  this.isLoading = true;
  let peticiones = [
    this.$http.ejecutar("GET", "StowagePlanningBillOfLadingHold-list", {
      StowagePlanningId: this.planificacionId,
      VesselHoldId: this.vesselHoldId,
      StowagePlanningBlHoldId: '',
    }),
    this.$http.ejecutar("GET", "StowagePlanningBlHold-list", {
      StowagePlanningId: this.planificacionId,
      VesselHoldId: this.vesselHoldId,
    }),
  ];

  Promise.all(peticiones)
    .then((responses) => {
      this.bls = responses[0].data.data;
      this.items = responses[1].data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.isLoading = false;
    });
}
function getBlHolds() {
  this.isLoading = true;
  this.$http
    .get("StowagePlanningBlHold-list", {
      StowagePlanningId: this.planificacionId,
      VesselHoldId: this.vesselHoldId,
    })
    .then((response) => {
      this.items = response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.isLoading = false;
    });
}
function getBls(blHoldId = '') {
  this.isLoading = true;
  this.$http
    .get("StowagePlanningBillOfLadingHold-list", {
      StowagePlanningId: this.planificacionId,
      VesselHoldId: this.vesselHoldId,
      StowagePlanningBlHoldId: blHoldId,
    })
    .then((response) => {
      this.bls = response.data.data;
      if(blHoldId) {
        let found = this.bls.find((item) => item.StowagePlanningBillOfLadingId == this.planned.StowagePlanningBillOfLadingId);
        if(found && this.modalActive){
          if(this.edit && this.oldStatus == 1){
            this.blAvailable = (found.AvailableWeight+this.EditWeightAsigned);
            this.blOccupied = (found.OccupiedWeight-this.EditWeightAsigned);
            this.PackingTonAvailable = Number.parseFloat(NumberFormater.setNum(this.planned.WeightAsigned));
            this.PackingQuantityAvailable = Number.parseFloat(NumberFormater.setNum(this.planned.QuantityAsigned));
          }else{
            this.blAvailable = found.AvailableWeight;
            this.blOccupied = found.OccupiedWeight;
            this.PackingTonAvailable = 0;
            this.PackingQuantityAvailable = 0;
          }
          this.PackagingList = found.PackagingJson ?? [];
          this.blDeclared = found.Weight;
        }
      }
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.isLoading = false;
    });
}
function editBl(item) {
  this.edit = true;
  this.setForm(item);
}
function toggleCollapse() {
  this.collapse = !this.collapse;
  if(!this.collapse) this.resetForm();
  if(this.collapse) this.getBls();
}
function resetForm() {
  let arrKeys = Object.keys(this.planned);

  for (let i = 0; i < arrKeys.length; i++) {
    switch (arrKeys[i]) {
      case "UnitMeasureWeightBlHoldId":
        this.planned[arrKeys[i]] = '52388680-623F-4E8A-8DB9-424605963856';
        break;
      case "Status":
        this.planned[arrKeys[i]] = 1;
        this.oldStatus = 1;
        break;
      default:
        this.planned[arrKeys[i]] = "";
    }
  }
  if(this.edit)
    this.getBls();

  this.edit = false;
  this.blOccupied = 0;
  this.blAvailable = 0;
  this.blDeclared = 0;
  this.EditWeightAsigned = 0;
  this.PackingTonAvailable = 0;
  this.PackingQuantityAvailable = 0;
  let inputWeight = this.$refs.weightInput.$el.querySelector("input");
  inputWeight.value = '';
  let QuantityInput = this.$refs.QuantityInput.$el.querySelector("input");
  QuantityInput.value = '';
  this.PackagingList = [];
  this.$v.$reset();
}
function setForm(blHold) {
  let arrKeys = Object.keys(this.planned);

  for (let i = 0; i < arrKeys.length; i++) {
    switch (arrKeys[i]) {
      case "WeightAsigned":
        this.planned[arrKeys[i]] = blHold[arrKeys[i]] ? blHold[arrKeys[i]].toFixed(2) : '';
        break;
      case "QuantityAsigned":
        this.planned[arrKeys[i]] = blHold[arrKeys[i]] ? blHold[arrKeys[i]].toFixed(2) : '';
        break;
      case "Status":
        this.planned[arrKeys[i]] = blHold[arrKeys[i]] == "ACTIVO" ? 1 : 0;
        this.oldStatus = blHold[arrKeys[i]] == "ACTIVO" ? 1 : 0;
        break;
      default:
        this.planned[arrKeys[i]] = blHold[arrKeys[i]] ? blHold[arrKeys[i]] : "";
    }
  }
  
  let inputWeight = this.$refs.weightInput.$el.querySelector("input");
  inputWeight.value = this.planned.WeightAsigned ? this.planned.WeightAsigned : '';
  let QuantityInput = this.$refs.QuantityInput.$el.querySelector("input");
  QuantityInput.value = this.planned.QuantityAsigned ? this.planned.QuantityAsigned : '';
  this.EditWeightAsigned = this.planned.WeightAsigned ? Number.parseFloat(NumberFormater.setNum(this.planned.WeightAsigned)) : '';
  this.$v.$touch();
  this.collapse = true;
  this.getBls(this.planned.StowagePlanningBlHoldId);
}
function formatedData() {
  let newBl = {
    ...this.planned,
    VesselHoldId: this.vesselHoldId,
    WeightAsigned: this.formatNumericValue(this.planned.WeightAsigned),
    QuantityAsigned: this.planned.QuantityAsigned ? this.formatNumericValue(this.planned.QuantityAsigned) : '',
  };
  if (!this.edit) {
    if (!this.FgGeneralCargo) {
      delete newBl.StowagePlanningBillOfLadingPackagingId;
      delete newBl.QuantityAsigned;
    }
    delete newBl.Status;
    delete newBl.StowagePlanningBlHoldId;

    return newBl;
  } else {
    if (!this.FgGeneralCargo) {
      delete newBl.StowagePlanningBillOfLadingPackagingId;
      delete newBl.QuantityAsigned;
    }
    return newBl
  }
}
function formatNumericValue(val) {
  return Number.parseFloat(val.replace(".", "").replace(",", "."));
}
function isEmpty(arr) {
  return arr.length == 0;
}
function statusSelectColor() {
  return this.planned.Status === 1;
}
function confirmation() {
  try {
    this.$v.planned.$touch();
    if (this.$v.planned.$error) {
      throw this.$t("label.errorsPleaseCheck");
    }
    this.statusConfirmation(
      this.oldStatus,
      this.planned.Status,
      this.submit,
      ``
    );
  } catch (e) {
    this.notifyError({ text: e });
  }
}
function submit() {
  try {
    this.$v.planned.$touch();
    if (this.$v.planned.$error) {
      throw this.$t("label.errorsPleaseCheck");
    }
    this.isSubmit = true;
    let route = this.edit ? "StowagePlanningBlHold-update" : "StowagePlanningBlHold-insert";
    let StowagePlanningBlHoldJson = this.formatedData;
    let metodo = this.edit ? "PUT" : "POST";

    this.$http
      .ejecutar(metodo, route, StowagePlanningBlHoldJson, {
        root: "StowagePlanningBlHoldJson",
      })
      .then((response) => {
        this.$notify({
          group: "container",
          title: "¡" + this.$t("label.success") + "!",
          text: response.data.data[0].Response,
          type: "success",
        });
        this.$emit("closed");
        this.resetForm();
        this.getBlHolds();
        this.getBls();
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
      .then(() => {
        this.isSubmit = false;
      });
  } catch (e) {
    this.notifyError({ text: e });
  }
}
function formatNumber(num) {
  if(num == 0 || num == '0') return 0;
  return formatMilDecimal(num);
}

function currentBl(Id) {
  this.planned.StowagePlanningBillOfLadingPackagingId = '';
  if(Id){
    this.planned.StowagePlanningBillOfLadingId = Id;
    let found = this.bls.find((item) => item.StowagePlanningBillOfLadingId == Id);
    if(found && this.modalActive){
      this.blAvailable = found.AvailableWeight;
      this.blOccupied = found.OccupiedWeight;
      this.blDeclared = found.Weight;
      this.PackagingList = found.PackagingJson ?? [];
    } else {
      this.blAvailable = '';
      this.blOccupied = '';
      this.blDeclared = '';
      this.PackagingList = [];
    }
  }else{
    this.blAvailable = '';
    this.blOccupied = '';
    this.blDeclared = '';
    this.PackagingList = [];
  }
}

function AssignAll() {
  if (!this.ValidateAssignmentButton) {
    let inputWeight = this.$refs.weightInput.$el.querySelector("input");
    if (this.FgGeneralCargo) {
      this.planned.WeightAsigned = this.DataPackaging.Weight ? this.DataPackaging.Weight.toFixed(2) : '';
      this.planned.QuantityAsigned = this.DataPackaging.Quantity ? this.DataPackaging.Quantity.toFixed(2) : '';
      inputWeight.value = this.planned.WeightAsigned;
      let QuantityInput = this.$refs.QuantityInput.$el.querySelector("input");
      QuantityInput.value = this.planned.QuantityAsigned;
      this.$v.planned.WeightAsigned.$touch();
      this.$v.planned.QuantityAsigned.$touch();
    } else {
      this.planned.WeightAsigned = this.blAvailable ? this.blAvailable.toFixed(2) : '';
      inputWeight.value = this.planned.WeightAsigned;
      this.$v.planned.WeightAsigned.$touch();
    }
  }
}

//Computeds
function formatedItems() {
  if (!this.holdName) return [];
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + this.$i18n.locale.slice(1);
  return this.items.map((item) => {
    return {
      ...item,
      Nro: item.Nro,
      NroBl: item.NroBl,
      PackagingName: item['PackagingName'+_lang] ?? '',
      CommodityName: item['CommodityName'+_lang] ?? '',
      Density: item.Density ? Number(item.Density) : 0,
      ClientName: item.ClientName ? item.ClientName : 'N/A',
      FormatWeight: NumberFormater.formatNumber(item.WeightAsigned, 2),
      FormatQuantity: NumberFormater.formatNumber(item.QuantityAsigned, 2),
      Usuario: item.TransaLogin ? item.TransaLogin : "N/A",
      Fecha: item.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
        : "N/A",
      Status: item.Status ? item.Status : "",
      _classes: item.Status === "INACTIVO" ? "table-danger" : "",
      _cellClasses: this.cellTableClasses,
    }
  });
}
function getModalTitle() {
  return this.holdName;
}
function blOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.bls.map(function(bl){
    chart.push({
      label: bl.NroBl,
      value: bl.StowagePlanningBillOfLadingId,
    })    
  })
  return chart;
}
function PackagingOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.PackagingList.map((item) => {
    chart.push({
      label: item['PackagingName'+_lang],
      value: item.StowagePlanningBillOfLadingPackagingId,
    })
  })
  return chart;
}
function currentAvailable() {
  if (this.edit && this.oldStatus == 1) {
    return this.total + this.EditWeightAsigned;
  }else{
    return this.total;
  }
}
function currentOccupied() {
  if(this.items.length == 0) return '0';
  let total = 0;
  for (let index = 0; index < this.items.length; index++) {
    if(this.items[index].FgActBlHold)
      total += this.items[index].WeightAsigned;
  }
  if(this.edit && this.oldStatus == 1){
    return (total - this.EditWeightAsigned);
  }else{
    return total;
  }
  
}
function getHoldBl() {
  let found = this.bls.find((item) => item.StowagePlanningBillOfLadingId == this.planned.StowagePlanningBillOfLadingId);
  return found ? found.NroBl : '';
}

function DataPackaging() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let Data = this.PackagingList.find(item => item.StowagePlanningBillOfLadingPackagingId == this.planned.StowagePlanningBillOfLadingPackagingId);
  return Data ? {
    ...Data, 
    PackagingName: Data['PackagingName'+_lang] ?? '',
    Weight: this.edit ? (this.PackingTonAvailable + Data.Weight) : Data.Weight,
    Quantity: this.edit ? (this.PackingQuantityAvailable + Data.Quantity) : Data.Quantity,
  } : {};
}

function ValidatePackaging() {
  return this.DataPackaging?.PackagingId?.toUpperCase() != process.env.VUE_APP_PACKAGING_ID_UNIDENTIFIED;
}

function ValidateAssignmentButton() {
  if (this.FgGeneralCargo) {
    return this.planned.StowagePlanningBillOfLadingPackagingId ? false : true;
  } else {
    return this.planned.StowagePlanningBillOfLadingId ? false : true;
  }
}

function Validations() {
  return {
    totalAvailable: this.currentAvailable, 
    blAvailable: this.blAvailable, 
    FgGeneralCargo: this.FgGeneralCargo, 
    PackagingQuantity: this.DataPackaging?.Quantity ?? 0,
    FgTotalQuantity: NumberFormater.setNum(this.planned.QuantityAsigned) == this.DataPackaging?.Quantity,
    PackagingWeight: this.DataPackaging?.Weight ?? 0,
    FgTotalWeight: NumberFormater.setNum(this.planned.WeightAsigned) == this.DataPackaging?.Weight,
  }
}

export default {
  name: "hold-modal",
  mixins: [ModalMixin, General],
  components: {
    Money,
  },
  props: {
    modal: Boolean,
    vesselHoldId: {
      type: String,
      default: '',
    },
    holdName: {
      type: String,
      default: '',
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data,
  validations() {
    return ManifestStowagePlanValidation(this.Validations);
  },
  directives: {
    money: VMoney,
  },
  methods: {
    toggle,
    editBl,
    toggleCollapse,
    resetForm,
    setForm,
    formatNumericValue,
    isEmpty,
    statusSelectColor,
    confirmation,
    submit,
    formatNumber,
    getBlHolds,
    loadData,
    getBls,
    currentBl,
    AssignAll,
  },
  computed: {
    fields,
    formatedItems,
    cellTableClasses,
    getModalTitle,
    blOptions,
    PackagingOptions,
    formatedData,
    currentAvailable,
    currentOccupied,
    getHoldBl,
    DataPackaging,
    ValidatePackaging,
    ValidateAssignmentButton,
    Validations,
    ...mapState({
      planificacionId: state => state.planificacionestiba.planificacionId,
      StowageFactor: state => state.planificacionestiba.StowageFactor,
      FgGeneralCargo: state => state.planificacionestiba.FgGeneralCargo,
    }),
  },
  watch: {
    modal: function (val) {
      this.toggle(val);
    },
    modalActive: function (val) {
      this.$emit("update:modal", val);
    },
    currentAvailable: function(newVal, oldVal) {
      this.generalAvailable = newVal;
    },
    currentOccupied: function(newVal, oldVal) {
      this.generalOccupied = newVal;
    },
  },
};
</script>